<template>
   <v-container>
      <crud
         api-path="category"
         title="Category"
         :field="field"
         key-id="id"
         with-edit
         with-delete
      >
      </crud>
   </v-container>
</template>

<script>
import crud from '@/components/crud2';

export default {
   components: { crud },
   data: () => ({
      field: [
         { value: 'id', label: '#', ro: true, tbl: true },
         { value: 'name', label: 'Nama', tbl: true, rules: [v => !!v || 'Nama harus diisi'], searchKey: true },
         { value: 'description', label: 'Deskripsi', tbl: true},
         { value: 'disabled', label: 'Disable', tbl: true, opts: [{value: 0, text:'No'}, {value: 1, text:'Yes'}]},
         { value: 'act', label: 'Aksi', ro: true, tbl: true, dis_sort: true }
      ],
      actions: [
         {icon: 'edit', fn: 'openForm', color: 'indigo'},
      ]
   }),
   methods: {
   }
};
</script>
